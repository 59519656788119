import React from 'react';
import PropTypes from 'prop-types';
import ChangeSubscriptionSection from "../../common/ChangeSubscriptionSection";
import tr from '../../../../translations';

const KlarnaSubscriptionPane = ({
  onShowCancelSubscriptionQuestion,
  onShowCheckout,
  subscriptionInfoText,
  subscriptionData,
  onShowChangeSubscription,
}) => {
  return (
    <div>
      <div>{subscriptionInfoText}</div>
      <hr/>
      {
        !subscriptionData.subscriptionState ? (<></>)
          : subscriptionData.subscriptionState === 'CHURN' ? (
              <>
                <button className="subscription-button" onClick={onShowCheckout}>
                  {tr('profile.subscription.startSub')}
                </button>
              </>
            ) : subscriptionData.subscriptionState === 'LEAD' ? (
              <>
                <button className="subscription-button" onClick={onShowCheckout}>
                  {tr('profile.subscription.startTrial')}
                </button>
              </>
              ) : subscriptionData.subscriptionState === 'GRACE' ? (
                <>
                  <button className="subscription-button" onClick={onShowCheckout}>
                    {tr('profile.subscription.addPayment')}
                  </button>
                  <hr/>
                  <button style={{border:'0px', textDecoration:'underline', backgroundColor:'transparent'}} onClick={onShowCancelSubscriptionQuestion}>
                    {tr('profile.subscription.endSub', 'KLARNA')}
                  </button>
                </> 
              ) : (
              <>
                <ChangeSubscriptionSection current={subscriptionData} onShowChangeSubscription={onShowChangeSubscription} submitChangesButtonText={tr('profile.subscription.changeSub', 'KLARNA')}/>
                <hr/>
                <button style={{border:'0px', textDecoration:'underline', backgroundColor:'transparent'}} onClick={onShowCancelSubscriptionQuestion}>
                  {tr('profile.subscription.endSub', 'KLARNA')}
                </button>
              </>
            )
      }
    </div>
  );
};

KlarnaSubscriptionPane.propTypes = {
  card: PropTypes.shape({
    cardNumber: PropTypes.string.isRequired,
  }),
  cardType: PropTypes.string,
  paymentData: PropTypes.object,
  planPrice: PropTypes.string,
  subscrUntil: PropTypes.string,
  onShowCancelSubscriptionQuestion: PropTypes.func,
};

export default KlarnaSubscriptionPane;
