import React, {useEffect, useState} from 'react';
import { ChangeSubscriptionPlans } from './ChangeSubscriptionPlans';
import {updateSubscription} from "../../lib/api";
import {getSessionId} from "../../lib/storage";
import tr from '../../../translations';

const ChangeSubscriptionSection = ({current, submitChangesButtonText, onShowChangeSubscription}) => {

  const [type, setType] = useState('0');
  const [waiting, setWaiting] = useState(false);

  useEffect(() => {
    setType(current.subscriptionType === 'YEARLY' ? '12' : '1');
  }, [current]);

  const handleSave = async (evt) => {
    evt.preventDefault();

    try {
      setWaiting(true);

      const data = {
        "periodCount": type,
        "periodUnit": "months"
      }
      await updateSubscription(getSessionId(), data)

    } catch (ex) {
      console.error(ex)
      alert(ex.message);
    } finally {
      alert(tr('changeSub.updatedSub', type));
      setWaiting(false);
      window.location.reload();
    }
  };

  return (
    <div>
      {current.subscriptionType === 'YEARLY' ? 
        <div>
          <button className="subscription-button" disabled={waiting} variant="primary" onClick={onShowChangeSubscription}>
              {submitChangesButtonText}
          </button>
        </div>
        :
        <div>
          <p><b>{tr('changeSub.tipCTA').text1}</b>{tr('changeSub.tipCTA').text2}</p>
          <ChangeSubscriptionPlans styling={"change-subscription white"} type={type} setType={setType} currentType={current.subscriptionType}/>
          <button className="subscription-button" disabled={type === (current.subscriptionType === 'YEARLY' ? '12' : '1') ? true : waiting} variant="primary" onClick={handleSave}>
              {submitChangesButtonText}
          </button>
      </div>}
    </div>);
};

export default ChangeSubscriptionSection;