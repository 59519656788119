import React from 'react';

import './ErrorMessage.css';

export default function ErrorMessage({ children }) {
  return (
    <div className="ErrorMessage" role="alert">
      {children}
    </div>
  );
}
