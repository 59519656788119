import React from 'react';
import tr from '../../../../translations';

const AppleSubscriptionPane = () => {
  return (
    <div>
      <p>{tr('profile.subscription.loading')}</p>
    </div>
  );
};

export default AppleSubscriptionPane;
