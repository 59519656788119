import React from 'react';
import Modal from 'react-bootstrap/Modal';

import { ButtonOld } from '../';

const CloseButtonModal = ({ children, show, title, onClose }) => {
  return (
    <Modal show={show} onHide={onClose}>
      {title && (
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}
      {children && <Modal.Body>{children}</Modal.Body>}
      <Modal.Footer>
        <ButtonOld variant="secondary" onClick={onClose}>
          Stäng
        </ButtonOld>
      </Modal.Footer>
    </Modal>
  );
};

export default CloseButtonModal;
