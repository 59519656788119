import React from 'react';
import PropTypes from 'prop-types';
import tr from '../../../../translations';

const MondidoSubscriptionPane = ({
  onShowCancelSubscriptionQuestion,
  onShowCheckout,
  subscriptionInfoText,
  subscriptionData
}) => {

  return (
    <div>
      <div>{subscriptionInfoText}</div>
      <hr/>
      {
        !subscriptionData.subscriptionState ? (<></>)
          : subscriptionData.subscriptionState === 'CHURN' ? (
              <>
                <button className="subscription-button" onClick={onShowCheckout}>
                  {tr('profile.subscription.startSub')}
                </button>
              </>
            ) : subscriptionData.subscriptionState === 'LEAD' ? (
              <button className="subscription-button" onClick={onShowCheckout}>
                Starta provperiod
              </button>
            ) : subscriptionData.subscriptionState === 'GRACE'? (
              <>
                <button className="subscription-button" onClick={onShowCheckout}>
                  Lägg till betalmetod
                </button>
                <hr/>
                <button style={{border:'0px', textDecoration:'underline', backgroundColor:'transparent'}} onClick={onShowCancelSubscriptionQuestion}>
                Avsluta prenumeration
                </button>
              </>
              ) : (
              <>
                <button style={{border:'0px', textDecoration:'underline', backgroundColor:'transparent'}} onClick={onShowCancelSubscriptionQuestion}>
                  Avsluta prenumeration
                </button>
              </>
            )
      }
    </div>

  );
};

MondidoSubscriptionPane.propTypes = {
  card: PropTypes.shape({
    cardNumber: PropTypes.string.isRequired,
  }),
  cardType: PropTypes.string,
  planPrice: PropTypes.string,
  subscrUntil: PropTypes.string,
  onShowCancelSubscriptionQuestion: PropTypes.func,
  onShowGiftCardButton: PropTypes.func,
};

export default MondidoSubscriptionPane;
