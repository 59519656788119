import React from 'react';
import tr from '../../../../translations';


const AppleSubscriptionPane = ({
  onShowCancelAppleModal,
  onShowHandleAppleModal,
  subscriptionInfoText,
  subscriptionData,
  onShowCheckout
}) => {
  return (
    <div>
      <div>{subscriptionInfoText}</div>
      <hr/>
      {
        !subscriptionData.subscriptionState ? (<></>)
          : subscriptionData.subscriptionState === 'CHURN' ? (
              <>
                <button className="subscription-button" onClick={onShowCheckout}>
                  {tr('profile.subscription.addPayment')}
                </button>
              </>
            ) : subscriptionData.subscriptionState === 'LEAD' ? (
              <button className="subscription-button" onClick={onShowCheckout}>
                {tr('profile.subscription.startTrial')}
              </button>
              ) : (
              <>
                <button className='subscription-button' onClick={onShowHandleAppleModal}>
                  {tr('profile.subscription.changeSub', 'APPLE')}
                </button>
                <hr/>
                <button style={{border:'0px', textDecoration:'underline'}}  onClick={onShowCancelAppleModal}>
                  {tr('profile.subscription.endSub', 'APPLE')}
                </button>
              </>
            )
      }
    </div>
    
  );
};

export default AppleSubscriptionPane;