import React from 'react';

import tr from '../../translations';

const {MONTHS, CURRENCY, SUBSCRIPTION} = require('../../config');

export const getSubscriptionInfoText = (res) => {
  if (!res) return '';

  const state = res.subscriptionState;
  const [subscriptionType, subscriptionTypeShort] = subscriptionTypeString(res.subscriptionType, res.paymentType);

  if (state === 'GRACE') {
    return (
      <>
        <p>
          {tr('profile.subscription.grace-addPayment')}
        </p>

        <p className='small'>{tr('profile.subscription.type-header')}</p>
        {res.nextPaymentAmount ?
          <p>{`${subscriptionType} ${priceString(res.nextPaymentAmount)}/${subscriptionTypeShort}`}</p>
        :
          <p>{`${subscriptionType}`}</p>
        }
        {res.nextPaymentAt ?
          <>
            <p className='small'>{tr('profile.subscription.nextPayment-header')}</p>
            <p className='red'>{formatDate(res.nextPaymentAt)}</p>
          </>
          :
          <></>
        }
      </>
    )
    }

  if (state === 'TRIAL' || state === 'PAYING') {
    const [subscriptionType, subscriptionTypeShort] = subscriptionTypeString(res.subscriptionType, res.paymentType);

    if (res.nextPaymentAmount) {
      if( state === 'TRIAL') {
        return (
          <>
            {res.nextPaymentInDays ?
              <>
                <p className='small'>{tr('profile.subscription.trialPeriod-header')}</p>
                <p>{res.nextPaymentInDays} {res.nextPaymentInDays === 1 ? "dag kvar" : "dagar kvar"}</p>
              </> :
              <></>
            }
            <p className='small'>{tr('profile.subscription.type-header')}</p>
              <p>{`${subscriptionType} ${priceString(res.nextPaymentAmount)}/${subscriptionTypeShort}`}</p>
            {res.nextPaymentAt ?
              <>
                <p className='small'>{tr('profile.subscription.nextPayment-header')}</p>
                <p>{formatDate(res.nextPaymentAt)}</p>
              </>
              :
              <></>
            }
          </>
        );
      }
      return (
        <>
            <p className='small'>{tr('profile.subscription.type-header')}</p>
              <p>{`${subscriptionType} ${priceString(res.nextPaymentAmount)}/${subscriptionTypeShort}`}</p>
            {res.nextPaymentAt ?
              <>
                <p className='small'>{tr('profile.subscription.nextPayment-header')}</p>
                <p>{formatDate(res.nextPaymentAt)}</p>
              </>
              :
              <></>
            }
        </>
      );
    }
    return (
      <>
        <p className='small'>{tr('profile.subscription.type-header')}</p>
        <p>{`${subscriptionType}`}</p>
        {res.nextPaymentAt ?
          <>
            <p className='small'>{tr('profile.subscription.nextPayment-header')}</p>
            <p>{formatDate(res.nextPaymentAt)}</p>
          </>
          :
          <></>
        }
      </>
    );
  }

  if (state === 'LEAD') {
    return (
      <>
        <p className='small'>{tr('profile.subscription.type-header')}</p>
        <p>{tr('profile.subscription.type-notActivated')}</p>
        <p>{tr('profile.subscription.addPayment-freeTrial').text1}<span style={{display:'inline-block'}}>{res.trialInDays} {tr('profile.subscription.addPayment-freeTrial').text2}</span></p>
      </>
    );
  }

  if (state === 'CHURN') {
    if (res.offer) {
      const subscriptionOffer = subscriptionOfferString(res.offer)
      return (
        <>
          <p className='small'>{tr('profile.subscription.type-header')}</p>
          <p>{tr('profile.subscription.type-inactive')}</p>
          <p className='small'>{tr('profile.subscription.offer-header')}</p>
          <p>{`${tr('profile.subscription.offer-addPayment')} ${priceString(res.offer.nextPaymentAmount, res.offer)} ${subscriptionOffer}`}</p>
        </>
      )
    }
    if (res.activeInDays) {
      return (
        <>
          <p className='small'>{tr('profile.subscription.type-header')}</p>
          <p>{tr('profile.subscription.type-inactive')}</p>
          <p className='small'>{tr('profile.subsription.activeDays-header')}</p>
          <p>{tr('subscription.activeInDays', res.activeInDays)}</p>
        </>
      );
    }
    return (
      <>
        <p className='small'>{tr('profile.subscription.type-header')}</p>
        <p>{tr('profile.subscription.type-inactive')}</p>
      </>
    );
  }
  return '';

  function subscriptionTypeString(type, payType) {
    return [SUBSCRIPTION.TYPES[type] + (payType === "APPLE" ? " via Apple": ""), SUBSCRIPTION.TYPES_SHORT[type]] || ['prenumeration', ''];
  }

  function subscriptionOfferString(offer) {
    if (offer.discountPeriodCount === 1) {
      return `första ${SUBSCRIPTION.SINGULAR[offer.subscriptionType]}`
    }
    return `i ${offer.discountPeriodCount} ${SUBSCRIPTION.PLURAL[offer.subscriptionType]}`;
  }

  function priceString(string, offer = null) {
    let perPeriod = '';
    if (offer?.discountPeriodCount > 1) {
      perPeriod = '/' + SUBSCRIPTION.SINGULAR[offer.subscriptionType];
    }
    return parseInt(string.replace(/\D/g, '')) / 10 + ' ' + CURRENCY + perPeriod;
  }

  function formatDate(d) {
    const date = new Date(d);
    return (parseInt(date.getDate()) + " " + MONTHS[date.getMonth()] + " " + parseInt(date.getFullYear()));

  }

};
