import { PAYMENT_TYPES } from '../../../constants';

import Apple from './AppleSubscriptionPane';
import Loading from './LoadingPane';
import Mondido from './MondidoSubscriptionPane';
import Stripe from './StripeSubscriptionPane';
import Klarna from './KlarnaSubscriptionPane';

const PaymentTypePanels = {
  [PAYMENT_TYPES.APPLE]: Apple,
  [PAYMENT_TYPES.MONDIDO]: Mondido,
  [PAYMENT_TYPES.STRIPE]: Stripe,
  [PAYMENT_TYPES.GIFT_CARD]: Stripe,
  [PAYMENT_TYPES.KLARNA]: Klarna,
  [PAYMENT_TYPES.NONE]: Stripe,
  default: Loading,
};

export const getSubscriptionPane = (paymentType) => {
  return PaymentTypePanels[paymentType] || PaymentTypePanels.default;
};
