import InputGroup from 'react-bootstrap/InputGroup';
import FormCheck from 'react-bootstrap/FormCheck';
import FormCheckLabel from 'react-bootstrap/FormCheckLabel';
import FormCheckInput from 'react-bootstrap/FormCheckInput';
import React from 'react';
import tr from '../../../translations';
const {PRICE, SUBSCRIPTION, CURRENCY} = require('../../../config');

export const ChangeSubscriptionPlans = ({styling, type, setType, currentType}) => {
    return (
        <InputGroup className="mb-3">
            <FormCheck className={type === '1' ? "change-subscription current": styling}>
                <FormCheckLabel htmlFor="month" className="typelabel">{SUBSCRIPTION.DURATION.MONTHLY}</FormCheckLabel>
                <br/>
                <FormCheckLabel htmlFor="month" className="sublabel">{currentType === 'MONTHLY' ? ' ': `${PRICE.MONTHLY} ${CURRENCY}`}</FormCheckLabel>
                <FormCheckInput
                    type="radio"
                    id="month"
                    name="group"
                    label="Månad 99 kr"
                    onChange={e => setType('1')}
                    checked={type === '1'}
                    className="change-subscription-radio"
                />
            </FormCheck>
                <p className='small  change-subscription-save-cta'>
                    <span role='img' aria-label="money mouth face">&#129297;</span>{tr('changeSub.savings')}
                </p>
            <FormCheck className={type === '12' ? "change-subscription current": styling}>
                <FormCheckLabel htmlFor="year" className="typelabel">{SUBSCRIPTION.DURATION.YEARLY}</FormCheckLabel>
                <br/>
                <FormCheckLabel htmlFor="year" className="sublabel">{currentType === 'YEARLY' ? ' ' : `${PRICE.YEARLY} ${CURRENCY}`}</FormCheckLabel>
                <FormCheckInput
                    type="radio"
                    id="year"
                    name="group"
                    onChange={e => setType('12')}
                    checked={type === '12'}
                    className="change-subscription-radio"
                />
            </FormCheck>
          </InputGroup>
    )
}