/**
 * Use the CSS tab above to style your Element's container.
 */
import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';

import './StripeCardField.css';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      borderRadius: '15px',
      color: '#32325d',
      fontFamily: 'Karla, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '14px',
      '::placeholder': {},
      paddingTop: '4px',
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const StripeCardField = ({ onChange }) => {
  return (
    <>
      <CardElement
        id="payment"
        options={CARD_ELEMENT_OPTIONS}
        {...(onChange ? { onChange } : {})}
        // onReady={(e) => c('Card element is ready for use', e)}
        // onChange={(e) => c('Card element changed', e)}
      />
    </>
  );
};

export default StripeCardField;
