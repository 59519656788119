import React, { useState, useRef, useEffect, useContext } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import hejsanFigure from '../images/hejsan-figur1.png';
import helpFigure from '../images/help-figur1.png';
import closeIcon from '../images/close.svg';
import openIcon from '../images/open.svg';
import abonnemangIcon from '../images/abonnemang-icon.svg';
import betalningIcon from '../images/betalning-icon.svg';
import kontoIcon from '../images/konto-icon.svg';
import downloadIcon from '../images/download-icon.svg';

import { connect } from 'react-redux';
import { navigate } from 'gatsby-link';

import { PAYMENT_TYPES } from '../constants';

import tr from '../../translations'; 
import { REGION } from '../../config';

import { getDateTextFromString } from '../lib/utils';
import { getLoginToken, getSessionId, setLoginToken } from '../lib/storage';
import {
  getPaymentInfo,
  cancelSubscription,
  me,
  sendUserFeedback,
  getSubscriptionInfo,
  createLoginTokenFromSession,
  getSubscription,
} from '../lib/api';
import { userPaymentInfoLoaded, userSubscriptionInfoLoaded } from '../redux/actions';

import { Base, Section } from '../components/common';
import SEO from '../components/seo';
import { ChangeEmailModal, ChangePasswordModal, InfoModal } from '../components/common/modals';
import StoreLogo from '../components/common/StoreLogo';
import AppLogo from '../components/common/AppLogo';

import CloseButtonModal from '../components/common/modals/CloseButtonModal';

import './profile.css';
import CancelSubscriptionModal from '../components/profile/CancelSubscriptionModal';
import { getSubscriptionPane } from '../components/profile/SubscriptionPane';
import SubscriptionForm from '../components/profile/StripeSubscription/SubscriptionForm';
import { getSubscriptionInfoText } from '../lib/subscription';
import ChangeSubscriptionModal from '../components/common/modals/ChangeSubscriptionModal';

import { AccordionContext } from 'react-bootstrap';

const { CURRENCY } = require('../../config');

function getCreditCardType(accountNumber) {
  if (/^5[1-5]/.test(accountNumber)) {
    return 'mastercard';
  } else if (/^4/.test(accountNumber)) {
    return 'visa';
  } else if (/^(5018|5020|5038|6304|6759|676[1-3])/.test(accountNumber)) {
    return 'maestro';
  } else if (/^3[47]/.test(accountNumber)) {
    return 'amex';
  }
  return 'kort';
}

const ProfilePage = ({
  accountSince,
  email,
  data,
  paymentInfo,
  paymentType,
  periodExpiryDate,
  subscriptionInfo,
  subscriptionText,
  userId,
  userIsLoaded,
  userIsLoggedIn,
  actionUserPaymentInfoLoaded,
  actionUserSubscriptionInfoLoaded,
}) => {
  const [copy, setCopy] = useState(false);
  const [hideText, setHideText] = useState(false);
  const [isBackgroundGreen, setIsBackgroundGreen] = useState(false);
  const [shareCodeLink, setShareCodeLink] = useState(null);
  const [showEmail, setShowEmail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showUpdated, setShowUpdated] = useState(false);
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [showCancelAppleModal, setShowCancelAppleModal] = useState(false);
  const [showHandleAppleModal, setShowHandleAppleModal] = useState(false);
  const [showHandleChangePaymentAppleModal, setShowHandleChangePaymentAppleModal] = useState(false);
  const [showGiftCardModal, setShowGiftCardModal] = useState(false);
  const [showCancelSubscription, setShowCancelSubscription] = useState(false);
  const [ShowAddCardModal, setShowAddCardModal] = useState(false);
  const [subscriptionInfoText, setSubscriptionInfoText] = useState();
  const [subscriptionData, setSubscriptionData] = useState({});
  const [showChangeSubscription, setShowChangeSubscription] = useState(false);
  const [subscriptionActiveUntil, setSubscriptionActiveUntil] = useState('');
  let inputValue = useRef(null);

  useEffect(() => {
    shareCode();
    getSubscription(getLoginToken()).then((r) => {
      if (r.nextPaymentAt) {
        setSubscriptionActiveUntil(r.nextPaymentAt);
      }
      const text = getSubscriptionInfoText(r);
      setSubscriptionData(r);
      setSubscriptionInfoText(text);
    });
  }, []);

  if (userIsLoaded && !userIsLoggedIn) {
    navigate('/');
    return null;
  }

  if (userIsLoaded && subscriptionInfo === false && data.state === 0) {
    getSubscriptionInfo(getSessionId(), userId).then((info) => {
      actionUserSubscriptionInfoLoaded(info);
    });
  }
  // Only fetch payment info for Mondido users
  if (userIsLoaded && paymentType === PAYMENT_TYPES.MONDIDO && paymentInfo === false) {
    getPaymentInfo(userId, getSessionId()).then((paymentInfo) => {
      actionUserPaymentInfoLoaded(paymentInfo);
    });
  }

  const SubscriptionPane = getSubscriptionPane(paymentType);

  let subscription = null;
  let debtUntil = null;
  let card = null;
  let subscrUntil = null;
  let planPrice = null;
  let cardType = null;
  let stripeSubscriptionId = null;
  let stripeSubscriptionScheduleId = null;
  let userData = data;
  let userState = userData?.state;
  let subscriptionId = userData?.paymentInfo.subscriptionId;
  let planId = userData?.paymentInfo.planId;
  let klarnaSubscriptionId = userData?.paymentInfo.klarnaSubscriptionId;

  if (paymentInfo) {
    ({ subscription, debtUntil, card } = paymentInfo);

    if (card) {
      subscrUntil = getDateTextFromString(subscription ? subscription.nextAt : debtUntil);
      planPrice = `${paymentInfo.subscriptionPrice} ${CURRENCY}`;
      cardType = getCreditCardType(card.cardNumber);
    } else if (debtUntil) {
      subscrUntil = getDateTextFromString(debtUntil);
    }
  }

  if (!subscrUntil) {
    subscrUntil = periodExpiryDate;
  }

  if (userData && !paymentInfo) {
    ({ stripeSubscriptionId, stripeSubscriptionScheduleId } = userData.paymentInfo);
  }

  const handleEmailShow = () => setShowEmail(true);
  const handlePasswordShow = () => setShowPassword(true);

  const handleEmailClose = (success) => {
    setShowEmail(false);
    success && setShowUpdated(true);
  };
  const handlePasswordClose = (success) => {
    setShowPassword(false);
    success && setShowUpdated(true);
  };
  const handleUpdatedClose = () => setShowUpdated(false);

  const handleShowCancelSubscriptionQuestion = () => {
    setShowCancelSubscription(true);
  };

  const handleCancelSubscriptionQuestionClose = (reloadPage) => {
    setShowCancelSubscription(false);
    if (reloadPage) {
      window.location.reload();
    }
  };
  const handleCancelSubscription = (reasons) => {
    return cancelSubscription(userId, reasons, getSessionId(), paymentType);
  };
  const handleSubmitFeedback = (feedback) => {
    return sendUserFeedback(feedback, getSessionId());
  };

  const handleCancelAppleModal = () => {
    setShowCancelAppleModal(true);
  };

  const handleCancelAppleModalClose = () => {
    setShowCancelAppleModal(false);
  };

  const handleAppleModal = () => {
    setShowHandleAppleModal(true);
  };

  const handleAppleModalClose = () => {
    setShowHandleAppleModal(false);
  };

  const handleChangePaymentAppleModal = () => {
    setShowHandleChangePaymentAppleModal(true);
  };

  const handleChangePaymentAppleModalClose = () => {
    setShowHandleChangePaymentAppleModal(false);
  };

  const handleGiftCardModal = () => {
    setShowGiftCardModal(true);
  };

  const handleReceiptModal = () => {
    setShowReceiptModal(true);
  };

  const handleReceiptModalClose = () => {
    setShowReceiptModal(false);
  };

  const handleGiftCardModalClose = () => {
    setShowGiftCardModal(false);
  };

  const handleAddCardModal = () => {
    setShowAddCardModal(true);
  };

  const handleShowCheckout = async () => {
    let loginToken = getLoginToken();
    if (!loginToken) {
      const result = await createLoginTokenFromSession(getSessionId()).catch((e) => {
        console.log(e);
        return {};
      });
      if (result.loginToken) {
        loginToken = result.loginToken;
        setLoginToken(loginToken);
      }
    }

    if (!loginToken) {
      handleAddCardModal();
    } else {
      window.location.href = `https://checkout.lylli.se/v2/?lt=${loginToken}`;
    }
  };

  const handleShowChangeSubscription = () => {
    setShowChangeSubscription(true);
  };

  const handleChangeSubscriptionClose = (success) => {
    setShowChangeSubscription(false);
    if (success) {
      window.location.reload();
    }
  };

  const handleAddCardModalClose = (reloadPage) => {
    setShowAddCardModal(false);
    if (reloadPage) {
      window.location.reload();
    }
  };

  const shareCode = async () => {
    return await me(getSessionId())
      .then((response) => {
        if (response) {
          setShareCodeLink(response.share_code);
        } else {
          throw new Error(tr('shareCode.notFound'));
        }
      })
      .catch((error) => {
        setShareCodeLink(false);
      });
  };

  const copyTextToClipboard = () => {
    inputValue.current.select();
    document.execCommand('copy');
    setCopy(true);
    setIsBackgroundGreen(true);
    setTimeout(() => {
      setCopy(false);
      setIsBackgroundGreen(false);
    }, 5000);
  };

  const ContextForIcon = ({ eventKey }) => {
    const currentEventKey = useContext(AccordionContext);
    const isCurrentEventKey = currentEventKey === eventKey;
    return (
      <div className="toggleIcon-container">
        {isCurrentEventKey ? (
          <img className="toggleIcon" src={openIcon} alt="" />
        ) : (
          <img className="toggleIcon" src={closeIcon} alt="" />
        )}
      </div>
    );
  };

  return (
    <Base style={{ backgroundColor: 'white' }}>
      <SEO title={tr('profile.title')} />
      <div
        style={{
          backgroundColor: 'white',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Section className="profile">
          <Row>
            <div
              className="profile-info-card"
              style={{ backgroundColor: 'var(--color-lylli-almond' }}
            >
              <div className="profile-info-card-text-container profile-info-card-text-mustard">
                <p className="small" style={{ opacity: '0.6' }}>
                  {tr('profile.welcome-subheader')}
                </p>
                <h3 className="profile-info-card-text-mustard">{tr('profile.welcome-header')}</h3>
              </div>
              <img className="figureImg" alt="" src={hejsanFigure} />
            </div>
          </Row>
          <hr className="section-seperator" />
          <Row>
            <Accordion defaultActiveKey="0">
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
                    <div className="card-header-icon-container">
                      <img src={abonnemangIcon} className="card-header-icon" alt="" />
                    </div>
                    <h5>{tr('profile.subscription-header')}</h5>
                    <ContextForIcon eventKey="0" />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <SubscriptionPane
                      className="white-col"
                      lg={{ offset: 1, span: 5 }}
                      md={{ offset: 0, span: 6 }}
                      {...{
                        card,
                        stripeSubscriptionId,
                        stripeSubscriptionScheduleId,
                        subscriptionText,
                        subscrUntil,
                        planPrice,
                        userState,
                        cardType,
                        onShowCancelSubscriptionQuestion: handleShowCancelSubscriptionQuestion,
                        onShowCancelAppleModal: handleCancelAppleModal,
                        onShowHandleAppleModal: handleAppleModal,
                        onShowReceiptModal: handleReceiptModal,
                        onShowGiftCardButton: handleGiftCardModal,
                        onShowAddCardModal: handleAddCardModal,
                        onShowCheckout: handleShowCheckout,
                        onShowChangeSubscription: handleShowChangeSubscription,
                        planId,
                        subscriptionId,
                        klarnaSubscriptionId,
                        subscriptionInfoText,
                        subscriptionData,
                      }}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Card.Header} variant="link" eventKey="1">
                    <div className="card-header-icon-container">
                      <img src={betalningIcon} alt="" className="card-header-icon" />
                    </div>
                    <h5>{tr('profile.payment-header')}</h5>
                    <ContextForIcon eventKey="1" />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <div>
                      {subscriptionData.subscriptionState === 'CHURN' ? (
                        <>
                          <div>
                            <p className="small">{tr('profile.payment.type-header')}</p>
                            <p>{tr('profile.payment.type-missing')}</p>
                          </div>
                        </>
                      ) : subscriptionData.subscriptionState === 'GRACE' ? (
                        <>
                          {subscriptionData.paymentType ? (
                            subscriptionData.paymentType === 'STRIPE' ? (
                              <div className="payment-section">
                                <div>
                                  <p className="small">{tr('profile.payment.type-header')}</p>
                                  <p className="red">{tr('profile.payment.type-card')}</p>
                                </div>
                                <button
                                  className="change-profile-info-button"
                                  onClick={handleShowCheckout}
                                >
                                  {tr('profile.changeBtn')}
                                </button>
                              </div>
                            ) : subscriptionData.paymentType === 'APPLE' ? (
                              <div className="payment-section">
                                <div>
                                  <p className="small">{tr('profile.payment.type-header')}</p>
                                  <p className="red">
                                    {subscriptionData.paymentType.charAt(0) +
                                      subscriptionData.paymentType.substring(1).toLowerCase()}
                                  </p>
                                </div>
                                <button
                                  className="change-profile-i-button"
                                  onClick={handleChangePaymentAppleModal}
                                >
                                  i
                                </button>
                              </div>
                            ) : (
                              <div className="payment-section">
                                <div>
                                  <p className="small">{tr('profile.payment.type-header')}</p>
                                  <p className="red">
                                    {subscriptionData.paymentType.charAt(0) +
                                      subscriptionData.paymentType.substring(1).toLowerCase()}
                                  </p>
                                </div>
                                <button
                                  className="change-profile-info-button"
                                  onClick={handleShowCheckout}
                                >
                                  {tr('profile.changeBtn')}
                                </button>
                              </div>
                            )
                          ) : (
                            <></>
                          )}
                        </>
                      ) : subscriptionData.subscriptionState === 'LEAD' ? (
                        <>
                          <button className="subscription-button" onClick={handleShowCheckout}>
                            {tr('profile.subscription.startTrial')}
                          </button>
                        </>
                      ) : (
                        /* subscriptionState 'PAYING' or 'TRIAL' */
                        <>
                          {subscriptionData.paymentType ? (
                            subscriptionData.paymentType === 'STRIPE' ? (
                              <div className="payment-section">
                                <div>
                                  <p className="small">{tr('profile.payment.type-header')}</p>
                                  <p>{tr('profile.payment.type-card')}</p>
                                </div>
                                <button
                                  className="change-profile-info-button"
                                  onClick={handleShowCheckout}
                                >
                                  {tr('profile.changeBtn')}
                                </button>
                              </div>
                            ) : (
                              <div className="payment-section">
                                <div>
                                  <p className="small">{tr('profile.payment.type-header')}</p>
                                  <p>
                                    {subscriptionData.paymentType.charAt(0) +
                                      subscriptionData.paymentType.substring(1).toLowerCase()}
                                  </p>
                                </div>
                                <button
                                  className="change-profile-info-button"
                                  onClick={handleShowCheckout}
                                >
                                  {tr('profile.changeBtn')}
                                </button>
                              </div>
                            )
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </div>
                    
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Card.Header} variant="link" eventKey="2">
                    <div className="card-header-icon-container">
                      <img src={kontoIcon} alt="" className="card-header-icon" />
                    </div>
                    <h5>{tr('profile.account-header')}</h5>
                    <ContextForIcon eventKey="2" />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <div>
                      <p className="small">{tr('profile.account.accountSince-header')}</p>
                      <p>{accountSince}</p>
                    </div>
                    <hr />
                    <div className="profile-info-container">
                      <div>
                        <p className="small">{tr('profile.account.email-header')}</p>
                        <p>{email}</p>
                      </div>
                      <button className="change-profile-info-button" onClick={handleEmailShow}>
                      {tr('profile.changeBtn')}
                      </button>
                    </div>
                    <hr />
                    <div className="profile-info-container">
                      <div>
                        <p className="small">{tr('profile.account.password-header')}</p>
                        <p>********</p>
                      </div>
                      <button className="change-profile-info-button" onClick={handlePasswordShow}>
                      {tr('profile.changeBtn')}
                      </button>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Card.Header} variant="link" eventKey="3">
                    <div className="card-header-icon-container">
                      <img src={downloadIcon} alt="" className="card-header-icon" />
                    </div>
                    <h5>{tr('profile.download-header')}</h5>
                    <ContextForIcon eventKey="3" />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    <div className="stores">
                      {tr('profile.download-text')}
                      <br />
                      <div className="stores-container">
                        <AppLogo
                          trackerName="account-profile"
                          type='APPLE_LIGHT'
                          region={REGION}
                        />
                        <AppLogo
                          trackerName="account-profile"
                          type='GOOGLE_LIGHT'
                          region={REGION}
                        />
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Row>
          <hr className="section-seperator" />
          <Row>
            <div
              className="profile-info-card"
              style={{ backgroundColor: 'var(--color-lylli-light-green)' }}
            >
              <div className="profile-info-card-text-container">
                <h4 className="profile-info-card-text-green">{tr('profile.help-header')}</h4>
                <div className="profile-info-card-link-container">
                  <a
                    className="profile-info-card-link dark-green"
                    href={tr('link.faq')}
                  >
                    {tr('profile.help.faq')}
                  </a>
                  <a className="profile-info-card-link" href={"mailto: " + tr('link.support')}>
                    {tr('profile.help.support')}
                  </a>
                </div>
              </div>
              <img src={helpFigure} alt="" className="figureImg" />
            </div>
          </Row>
          {false && shareCodeLink ? (
            <>
              <Row style={{ marginBottom: '30px' }}>
                <Col md={12} lg={{ offset: 1, span: 10 }} className="white-col">
                  <h2 className="text-left">{tr('inviteFriend-header')}</h2>
                  <p>
                    {tr('inviteFriend-text', copy)}
                  </p>
                  <input
                    readOnly
                    id="share-code-link"
                    value={`${tr('link.inviteFriend')}=${shareCodeLink}`}
                    ref={inputValue}
                  />
                  <button
                    id="copy-button"
                    className={isBackgroundGreen ? 'background-green' : 'background-black'}
                    onClick={copyTextToClipboard}
                  >
                    {tr('inviteFriend-textCopied', copy)}
                  </button>
                </Col>
              </Row>
            </>
          ) : null}
        </Section>
      </div>
      <ChangeSubscriptionModal
        show={showChangeSubscription}
        onClose={handleChangeSubscriptionClose}
        current={subscriptionData}
      />
      <ChangeEmailModal show={showEmail} onClose={handleEmailClose} />
      <ChangePasswordModal show={showPassword} onClose={handlePasswordClose} />
      <InfoModal show={showUpdated} onClose={handleUpdatedClose}>
        {tr('accountUpdated')}
      </InfoModal>
      <CancelSubscriptionModal
        show={showCancelSubscription}
        onCancelSubscription={handleCancelSubscription}
        onClose={handleCancelSubscriptionQuestionClose}
        onSubmitWrittenFeedback={handleSubmitFeedback}
        activeUntil={subscriptionActiveUntil}
      />

      {/* Cancel Apple subscription modal */}
      <InfoModal show={showCancelAppleModal} onClose={handleCancelAppleModalClose}>
        <h5>{tr('apple.cancelSubscription-header')}</h5>
        <br />
        <p>
          {tr('apple-step1')}<br /> 
          {tr('apple-step2')}<br /> 
          {tr('apple-step3')}<br /> 
          {tr('apple-step4')}<br /> 
          {tr('apple.cancelSubscription-step5')}
        </p>
        <p>
          {tr('apple.getSupport')['text1']}
          <a href={"mailto: " + tr('link.support')}>{tr('link.support')}</a>.
        </p>
      </InfoModal>

      {/* Handle Apple subscription modal */}
      <InfoModal show={showHandleAppleModal} onClose={handleAppleModalClose}>
        <h5>{tr('apple.handleSubscription-header')}</h5>
        <br />
        <p>
          {tr('apple-step1')}<br /> 
          {tr('apple-step2')}<br /> 
          {tr('apple-step3')}<br /> 
          {tr('apple-step4')}<br /> 
          {tr('apple.handleSubscription-step5')}<br />
        </p>
        <p>
          {tr('apple.getSupport')['text1']}
          <a href={"mailto: " + tr('link.support')}>{tr('link.support')}</a>.
        </p>
      </InfoModal>

      {/* Handle change payment type for Apple*/}
      <InfoModal
        show={showHandleChangePaymentAppleModal}
        onClose={handleChangePaymentAppleModalClose}
      >
        <h5>{tr('apple.changeSubscription-header')}</h5>
        <br />
        <p>
          {tr('apple.changeSubscription-text1')}
        </p>
        <br />
        <b>{tr('apple.changeSubscription-text2')}</b>
        <p>
          {tr('apple-step1')}<br /> 
          {tr('apple-step2')}<br /> 
          {tr('apple-step3')}<br /> 
          {tr('apple-step4')}<br /> 
          {tr('apple.cancelSubscription-step5')}
        </p>
        <p>
          {tr('apple.getSupport')['text1']}
          <a href={"mailto: " + tr('link.support')}>{tr('link.support')}</a>.
        </p>
      </InfoModal>

      {/* Modal for receipts */}
      <InfoModal show={showReceiptModal} onClose={handleReceiptModalClose}>
        {userIsLoaded && paymentType === PAYMENT_TYPES.KLARNA ? (
          <>
            <p>
              {(tr('receipts.klarna')).text1}
              <a href={tr('link.klarna')} target="_blank" rel="noreferrer">
                {(tr('receipts.klarna')).linkText}
              </a>
              {(tr('receipts.klarna')).text2}
            </p>
          </>
        ) : (
          <>
            <p>
              {tr('receipts.contactSupport')['text1']}
              <a href={"mailto: " + tr('link.support')}>{tr('link.support')}</a>
              {tr('receipts.contactSupport')['text2']}
            </p>
          </>
        )}
      </InfoModal>
      {/* Modal for gift card */}
      <InfoModal show={showGiftCardModal} onClose={handleGiftCardModalClose}>
        {(tr('giftcard')).text1}
        <a href={"mailto: " + tr('link.support')}>{tr('link.support')}</a>
        {(tr('giftcard')).text2}
      </InfoModal>

      {/* Modal for changing Modido card */}
      <CloseButtonModal show={ShowAddCardModal} onClose={handleAddCardModalClose}>
        {!hideText && (
          <>
            <h2>{tr('modido.changeCard-header')}</h2>
            <p className="payment-text">
              {tr('modido.changeCard-text')}
            </p>
          </>
        )}
        <SubscriptionForm setHideText={setHideText} />
      </CloseButtonModal>
    </Base>
  );
};

export default connect(
  ({
    user: {
      email,
      accountSince,
      data,
      paymentInfo,
      paymentType,
      periodExpiryDate,
      subscriptionInfo,
      subscriptionText,
      userId,
      userIsLoaded,
      userIsLoggedIn,
    },
  }) => ({
    email,
    accountSince,
    data,
    paymentInfo,
    paymentType,
    periodExpiryDate,
    subscriptionInfo,
    subscriptionText,
    userId,
    userIsLoaded,
    userIsLoggedIn,
  }),
  {
    actionUserPaymentInfoLoaded: userPaymentInfoLoaded,
    actionUserSubscriptionInfoLoaded: userSubscriptionInfoLoaded,
  }
)(ProfilePage);
