import React, { useState } from 'react';

import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import { getSessionId } from '../../../lib/storage';
import StoreLogo from '../../common/StoreLogo';
import StripeCardField from '../../giftcard/StripeCardField';
import { createStripeSubscription, stripeSubscriptionComplete } from '../../../lib/api';
import ErrorMessage from './ErrorMessage';
import SubmitButton from './SubmitButton';

import './SubscriptionForm.css';

const { STRIPE_PUBLISHABLE_KEY } = require('../../../../config');

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
const DEFAULT_PAYMENT_ERROR =
  'Betalningen kunde inte genomföras, vänligen försök igen eller kontakta support på kontakt@lylli.se';

const CheckoutForm = ({ setHideText }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    if (error) {
      elements.getElement('card').focus();
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }

    const cardElement = elements.getElement(CardElement);

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (payload.error) {
      setProcessing(false);
      setError(payload.error);
    } else {
      createSubscription({
        paymentMethodId: payload.paymentMethod.id,
      });
    }
  };

  function handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    paymentMethodId,
    isRetry,
  }) {
    if (subscription && subscription.status === 'active') {
      return { subscription };
    }

    if (subscription && subscription.status === 'trialing') {
      let setupIntent = subscription.pending_setup_intent;

      if (setupIntent) {
        if (setupIntent.status === 'requires_action') {
          return stripe
            .confirmCardSetup(setupIntent.client_secret, {
              payment_method: paymentMethodId,
            })
            .then((result) => {
              if (result.error) {
                throw result;
              } else {
                if (result.setupIntent.status === 'succeeded') {
                  return { subscription };
                }
              }
            });
        } else if (setupIntent.status === 'succeeded') {
          return { subscription };
        }
      }
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent;
    if (paymentIntent) {
      if (
        paymentIntent.status === 'requires_action' ||
        (isRetry === true && paymentIntent.status === 'requires_payment_method')
      ) {
        return stripe
          .confirmCardPayment(paymentIntent.client_secret, {
            payment_method: paymentMethodId,
          })
          .then((result) => {
            if (result.error) {
              throw result;
            } else {
              if (result.paymentIntent.status === 'succeeded') {
                subscription.status = 'active';
                return {
                  subscription: subscription,
                };
              }
            }
          });
      } else {
        return { subscription };
      }
    }
    return { subscription };
  }

  function handleRequiresPaymentMethod({ subscription }) {
    if (subscription.status === 'active' || subscription.status === 'trialing') {
      return { subscription };
    } else {
      throw new Error(DEFAULT_PAYMENT_ERROR);
    }
  }

  const onSubscriptionComplete = (result) => {
    stripeSubscriptionComplete(getSessionId())
      .then((response) => {
        if (result.subscription.status === 'active' || result.subscription.status === 'trialing') {
          setSuccess(true);
          setHideText(true);
        } else {
          throw new Error(DEFAULT_PAYMENT_ERROR);
        }
      })
      .catch((err) => {
        throw new Error(DEFAULT_PAYMENT_ERROR);
      });
  };

  const createSubscription = ({ paymentMethodId }) => {
    return createStripeSubscription(paymentMethodId, getSessionId())
      .then((result) => {
        if (result.error) {
          throw result;
        }
        return result;
      })
      .then((result) => {
        return {
          paymentMethodId: paymentMethodId,
          subscription: result,
        };
      })
      .then(handlePaymentThatRequiresCustomerAction)
      .then(handleRequiresPaymentMethod)
      .then(onSubscriptionComplete)
      .catch((error) => {
        let errorMessage = (error.error || {}).message || error.message;
        setError(errorMessage);
        setProcessing(false);
      });
  };

  return success ? (
    <div className="Result">
      <div className="ResultTitle" role="alert">
        Kortuppgifterna uppdaterades!
      </div>
      <div className="ResultMessage">Tack för att du väljer Lylli!</div>
      <div className="ResultAppMessage">
        Kom ihåg att ladda ner appen, det är i appen du hittar alla våra böcker.
      </div>
      <div className="storeLogoDiv">
        <StoreLogo trackerName="paymentSuccess" type={StoreLogo.TYPES.APPLE} />
        <StoreLogo trackerName="paymentSuccess" type={StoreLogo.TYPES.GOOGLE} />
      </div>
    </div>
  ) : (
    <form className="Form" onSubmit={handleSubmit}>
      <fieldset className="FormGroup">
        <StripeCardField
          onChange={(e) => {
            setError(e.error);
            setCardComplete(e.complete);
          }}
        />
      </fieldset>
      {error && <ErrorMessage>{error.message ? error.message : error}</ErrorMessage>}

      <SubmitButton center processing={processing} error={error} disabled={!stripe}>
        Lägg till betalkort
      </SubmitButton>
    </form>
  );
};

export default function SubscriptionForm({ setHideText }) {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm setHideText={setHideText} />
    </Elements>
  );
}
