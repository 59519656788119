import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ButtonOld } from '../common/';
import Form from 'react-bootstrap/Form';
import tr from '../../../translations';

const QUESTIONS = tr('cancelSub.reasons');

const CancelSubscriptionModal = ({
  show,
  onClose,
  onCancelSubscription,
  onSubmitWrittenFeedback,
  activeUntil,
}) => {
  const [step, setStep] = useState(1);
  const [reason, setReason] = useState({});
  const [feedback, setFeedback] = useState('');

  const handleClose = () => {
    const reloadPage = step >= 3;

    onClose(reloadPage);

    // Reset form if it is opened up again
    setTimeout(() => {
      setStep(1);
      setReason({});
      setFeedback('');
    }, 100);
  };

  const handleCancelSubscription = async () => {
    if (Object.keys(reason).length) {
      const selectedReason =
        Object.keys(reason)
          .filter((r) => reason[r])
          .join(',') || 'none-selected';
      setStep(2);

      await onCancelSubscription(selectedReason);

      setStep(3);
    } else {
      document.getElementById('cta-chooseOption').style.display = 'block';
    }
  };

  const handleSendFeedback = async () => {
    await onSubmitWrittenFeedback({feedback: feedback, reason: Object.keys(reason)[0]});
    handleClose(true);
  };

  let subtitle = tr('cancelSub-title', false);
  let title = tr('cancelSub-subtitle', false);
  let body, footer;

  if (step === 1 || step === 2) {
    body = (
      <div style={{ padding: '0 1rem' }}>
        <div style={{ padding: '0 1rem' }}>
          {QUESTIONS.map((props) => (
            <Form.Check
              disabled={step === 2}
              key={props.id}
              {...props}
              type="radio"
              style={{ marginBottom: '6px' }}
              checked={reason[props.id] || false}
              onChange={({ target: { id, checked } }) => setReason({ [id]: checked })}
            />
          ))}
        </div>
      </div>
    );
    footer = (
      <Modal.Footer
        style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}
      >
        <p id="cta-chooseOption" style={{ color: 'red', display: 'none' }}>
          <i>{tr('cancelSub.chooseReason')}</i>
        </p>
        <div>
          <ButtonOld className="white" style={{ margin: '0.25rem' }} disabled={step === 2} onClick={handleClose}>
            {tr('cancelSub.stayCTA')}
          </ButtonOld>
          <ButtonOld
            style={{ margin: '0.25rem' }}
            disabled={step === 2}
            onClick={handleCancelSubscription}
          >
            {tr('cancelSub.cancelCTA')}
          </ButtonOld>
        </div>
      </Modal.Footer>
    );
  } else {
    subtitle = tr('cancelSub-title', true);
    title = tr('cancelSub-subtitle', true);
    body = (
      <div style={{ padding: '0 1rem' }}>
        {new Date(new Date(activeUntil).toDateString()) > new Date(new Date().toDateString()) ? (
          <h5 style={{ textAlign: 'left', color: '#604077' }}>
            {tr('cancelSub.endSub-activeUntil', activeUntil)}
          </h5>
        ) : (
          <div></div>
        )}
        <p>
          {(tr('cancelSub.feedback')).text1}
          <br />
          {(tr('cancelSub.feedback')).text2}
        </p>

        <p>
          {(tr('cancelSub.confirmation')).text1}
          <span aria-label="hjärta" role="img">
            💜
          </span>
          {(tr('cancelSub.confirmation')).text2}
        </p>

        <hr />
        <Form.Control
          as="textarea"
          style={{ marginBottom: '6px' }}
          value={feedback}
          onChange={({ target: { value } }) => setFeedback(value)}
        />
      </div>
    );
    footer = (
      <Modal.Footer>
        <ButtonOld className="white" onClick={handleClose}>
          {tr('cancelSub.feedback-closeCTA')}
        </ButtonOld>
        <ButtonOld onClick={handleSendFeedback}>{tr('cancelSub.feedback-sendCTA')}</ButtonOld>
      </Modal.Footer>
    );
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <div style={{ margin: '30px 30px 0px 30px' }}>
            {subtitle && <h5 style={{ textAlign: 'left', color: '#604077' }}>{subtitle}</h5>}
            {title && <h2 style={{ textAlign: 'left', marginTop: '10px' }}>{title}</h2>}
          </div>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        {footer}
      </Modal>
    </>
  );
};

export default CancelSubscriptionModal;
